/* eslint-disable no-console */
import router from '@/router/index.router';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import SignalrAppInstance from '@/signalr/App.signalr';
import { useLoader, useNotif } from '@orion.ui/orion';
import useNavLoaderService from '@/services/NavLoaderService';

const isFirefox = /Firefox/.test(window.navigator.userAgent);

const loggerRequestInterceptor = function (config: InternalAxiosRequestConfig) {
	if (!isFirefox) {
		console.groupCollapsed(
			`%cTry ${config.method?.toUpperCase()} ${config.url}`,
			'color: white; font-size:10px; background: #198CFF; font-weight: 400; padding : 5px;',
		);
		console.log(config);
		console.groupEnd();
	}

	return config;
};

const loggerResponseInterceptor = function (response: AxiosResponse) {
	const url = response.config.url?.replace(response.config.baseURL ?? '', '');

	if (!isFirefox) {
		console.groupCollapsed(
			`%cSuccess ${response.config.method?.toUpperCase()} ${response.status} ${url}`,
			'color: white; font-size:10px; background: #00d883; font-weight: 400; padding : 5px;',
		);
		console.log(response?.data);
		console.groupEnd();
	}

	return response;
};

let errorNotifInstance: Undef<OrionNotif>;
const loggerResponseErrorInterceptor = function (error: AxiosError) {
	if (!error.response) return Promise.reject(error);

	const url = error.response.config.url?.replace(error.response.config.baseURL ?? '', '');

	if (!isFirefox) {
		console.groupCollapsed(
			`%cError ${error.response.status} ${url} ${error.response.statusText}`,
			'color: white; font-size:10px; background: #ff0033; font-weight: 400; padding : 5px;',
		);
		console.log(error.response?.data);
		console.groupEnd();
	}

	return Promise.reject(error);
};

const responseErrorInterceptorAsync = async function (error: AxiosError) {
	if (!error.response) return Promise.reject(error);

	const url = error.response.config.url?.replace(error.response.config.baseURL ?? '', '');

	if (error.response.status === 401 && url?.indexOf('refreshtoken') === -1) {
		const SessionManagerImport = () => import('@/utils/SessionManager');
		const SessionManager = await SessionManagerImport();
		const result = await SessionManager.default?.getTokenAsync();

		if (result) {
			return axios.request(error.response.config);
		} else {
			useLoader().hide();

			localStorage.removeItem('userType');
			if (router.currentRoute.value.name !== 'Login' && router.currentRoute.value.name !== 'Signup') {
				await SignalrAppInstance.stop();
				await router.push({
					name: 'Login',
					query: { redirect: router.currentRoute.value.fullPath },
				});
				// Pour reset VueRouter
				window.location.reload();
			}
		}
	} else if ((error.response?.data as any)?.code !== 'discussion_missing'
		&& !(window.location.pathname.startsWith('/signup') && error.response.status === 401)) {
		useLoader().hide();
		useNavLoaderService().hide();

		errorNotifInstance?.close();
		errorNotifInstance = useNotif.danger(
			`Oops...`,
			typeof error?.response?.data === 'string'
				? error?.response?.data
				: `Une erreur est survenue, merci de réessayer plus tard`,
		);
	}

	return Promise.reject(error);
};

export {
	loggerRequestInterceptor,
	loggerResponseInterceptor,
	loggerResponseErrorInterceptor,
	responseErrorInterceptorAsync,
};
